import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import AutoPlayVideo from "../../components/AutoPlayVideo";

export default function Hero() {
  return (
    <section className="hero">
      <div className="hero__bg-video">{AutoPlayVideo("bg/header.mp4")}</div>
      <div className="hero__content">
        <img src="utils/hero-title.png" alt="walashi" />

        <h1>vidéaste</h1>
        <h1>photographe</h1>
        <h1>graphiste</h1>

        <div className="hero__see-more">
          <a className="button-see-more" href="/#menu">
            Voir plus
            <FontAwesomeIcon icon={faChevronDown} />
          </a>
        </div>
      </div>
    </section>
  );
}
