import { useMediaQuery } from "react-responsive";
import _ from "lodash";

export default function Header() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const currentSection = window.location.href.split("/")[3];

  const sections = [
    { name: "Photographie", slug: "photographie" },
    { name: "Clips Musicaux", slug: "musique" },
    { name: "Graphisme", slug: "graphisme" },
    { name: "Vidéos", slug: "video" },
    { name: "Mariage", slug: "mariage" },
    { name: "Immobilier", slug: "immobilier" },
  ];
  console.log(window.location.href.split("/").length);
  return (
    <header className="header">
      {isDesktopOrLaptop && (
        <>
          <a className="button button__white" href="/">
            Acceuil
          </a>
          <ul>
            {sections.map((section) => (
              <li>
                <a
                  className={`button button__transparent ${
                    currentSection === section.slug ? "button__white" : ""
                  }`}
                  href={`/${section.slug}`}
                >
                  {section.name}
                </a>
              </li>
            ))}
          </ul>
          <a className="button button__white" href="/contact">
            Contact
          </a>
        </>
      )}
      {isTabletOrMobile &&
        sections.map((s) => s.slug).includes(currentSection) && (
          <a className="button button__white" href="/">
            Acceuil
          </a>
        )}
    </header>
  );
}
