import { React, useState } from "react";
import Carousel from "better-react-carousel";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";

import { ECarouselType } from "./ECarouselType";

export default function Slider(params) {
  const { items, type } = params;

  const [show, setShow] = useState(false);
  const [displayPhoto, setDisplayPhoto] = useState();

  const handleClose = () => {
    setDisplayPhoto(null);
    setShow(false);
  };
  const handleShow = (element) => {
    setDisplayPhoto(element);
    setShow(true);
  };

  return (
    <>
      <div className="carousel">
        <Carousel cols={3} rows={1} gap={10} loop>
          {items.map((i) => (
            <Carousel.Item>
              {type === ECarouselType.PHOTO && (
                <img
                  className="carousel__item"
                  onClick={() => handleShow(i)}
                  width="100%"
                  src={i.src}
                  alt={i.alt}
                />
              )}
              {type === ECarouselType.VIDEO && (
                <div
                  className="carousel__item--video"
                  onClick={() => handleShow(i)}
                >
                  {" "}
                  <img
                    className="carousel__item "
                    width="100%"
                    src={i.img}
                    alt={i.alt}
                  />
                  <FontAwesomeIcon icon={faCirclePlay} />
                </div>
              )}
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <Modal centered size="lg" show={show} onHide={handleClose}>
        <Modal.Body style={{ backgroundColor: "transparant" }} closeButton>
          {displayPhoto && displayPhoto.type === ECarouselType.PHOTO && (
            <img
              onClick={handleShow}
              width="100%"
              src={displayPhoto.src}
              alt={displayPhoto.alt}
            />
          )}

          {displayPhoto && displayPhoto.type === ECarouselType.VIDEO && (
            <div style={{ padding: "75% 0 0 0", position: "relative" }}>
              <iframe
                src={displayPhoto.src}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                title="video"
              ></iframe>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
