import { ECarouselType } from "../utils/ECarouselType";
import AutoPlayVideo from "../components/AutoPlayVideo";
import Slider from "../utils/Slider";

const photos1 = [
  { src: "/photos/1.png", alt: "younes", type: ECarouselType.PHOTO },
  { src: "/photos/2.png", alt: "house", type: ECarouselType.PHOTO },
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
  { src: "/photos/1.png", alt: "younes", type: ECarouselType.PHOTO },
];

const photos2 = [
  { src: "/photos/2.png", alt: "house", type: ECarouselType.PHOTO },
  { src: "/photos/2.png", alt: "house", type: ECarouselType.PHOTO },
  { src: "/photos/2.png", alt: "house", type: ECarouselType.PHOTO },
  { src: "/photos/2.png", alt: "house", type: ECarouselType.PHOTO },
  { src: "/photos/2.png", alt: "house", type: ECarouselType.PHOTO },
];

const photos3 = [
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
  { src: "/photos/3.png", alt: "flag", type: ECarouselType.PHOTO },
];

const videos1 = [
  {
    src: "https://player.vimeo.com/video/905252553?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    img: "/photos/preview.jpg",
    type: ECarouselType.VIDEO,
  },
];

export default function Graphisme() {
  return (
    <section className="page">
      <div className="page__bg-video">{AutoPlayVideo("bg/bg-walashi.mp4")}</div>
      <Slider items={photos1} type={ECarouselType.PHOTO} />
      <Slider items={photos2} type={ECarouselType.PHOTO} />
      <Slider items={videos1} type={ECarouselType.VIDEO} />
      <Slider items={photos3} type={ECarouselType.PHOTO} />
    </section>
  );
}
