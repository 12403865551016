export default function Footer() {
  return (
    <footer className="footer">
      <div>
        <a
          href="https://www.instagram.com/walashi.png/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/icon/insta-icon.png" alt="instagram" />
        </a>
      </div>
      <p>© walashi.png - 2024</p>
    </footer>
  );
}
