import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/main.scss";
import Home from "./pages/home";
import Layout from "./pages/Layout";
import Photographie from "./pages/Photographie";
import Musique from "./pages/Musique";
import Immobilier from "./pages/Immobilier";
import Mariage from "./pages/Mariage";
import Video from "./pages/Video";
import Graphisme from "./pages/Graphisme";
import Contact from "./pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/photographie" element={<Photographie />} />
          <Route path="/musique" element={<Musique />} />
          <Route path="/immobilier" element={<Immobilier />} />
          <Route path="/mariage" element={<Mariage />} />
          <Route path="/video" element={<Video />} />
          <Route path="/graphisme" element={<Graphisme />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
