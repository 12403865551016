import AutoPlayVideo from "../../components/AutoPlayVideo";

export default function Menu() {
  const Section = (props) => (
    <div className="menu__sections-section">
      <img src={props.image} alt={props.title} />
      <p>{props.title}</p>
      <a className="button button__white" href={props.url}>
        Voir
      </a>
    </div>
  );
  return (
    <section id="menu" className="menu">
      <div className="menu__bg-video">
        {AutoPlayVideo("bg/bg-walashi.mp4")}
        <div className="menu__sections">
          <Section
            image="section/photographie.png"
            title="photographie"
            url="/photographie"
          />
          <Section image="section/mariage.png" title="mariage" url="/mariage" />
          <Section image="section/video.png" title="vidéos" url="/videos" />
          <Section
            image="section/musique.png"
            title="clips musicaux"
            url="/musique"
          />
          <Section
            image="section/graphisme.png"
            title="graphisme"
            url="/graphisme"
          />
          <Section
            image="section/immobilier.png"
            title="immobiliers"
            url="/immobilier"
          />
        </div>
      </div>
    </section>
  );
}
