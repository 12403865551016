import Hero from './Hero'
import Menu from './Menu'

export default function Home() {
  return (
    <>
      {Hero()}
      {Menu()}
    </>
  )
}
