import AutoPlayVideo from "../components/AutoPlayVideo";

export default function Contact() {
  return (
    <section className="page">
      <div className="page__bg-video">{AutoPlayVideo("bg/bg-walashi.mp4")}</div>

      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScTOrBUFZlSAsE4H06HrHCMRD8E78ITFOLYliCmmRdeF9I9jA/viewform?embedded=true"
          width="640"
          height="1207"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="contact"
        >
          Chargement…
        </iframe>
      </div>
    </section>
  );
}
